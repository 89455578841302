'use client';

import useShowGridCustomiser from 'src/hooks/useShowGridCustomiser';
import React, { CSSProperties, PropsWithChildren, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

const GridSectionWrapper = (
	props: PropsWithChildren<{
		className?: string;
		style?: CSSProperties;
		noPad?: boolean;
		id?: string;
	}>
) => {
	const [_showGridCustomiser, setShowGridCustomiser] = useShowGridCustomiser();

	const memoedClassName = useMemo(
		() =>
			twMerge(
				`h-full bg-container-bg border-container-border rounded border overflow-hidden`,
				props.noPad ? '' : 'p-2',
				props.className
			),
		[props.noPad, props.className]
	);

	return (
		<div
			onClick={() => setShowGridCustomiser(false)}
			id={props.id}
			style={props.style}
			className={memoedClassName}
		>
			{props.children}
		</div>
	);
};

export default GridSectionWrapper;
