'use client';

import {
	BigNum,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { useMemo } from 'react';
import {
	AccountHealth,
	MobileAndTabletAccountHealth,
} from 'src/components/GridSections/AccountHealth/AccountHealth';
import useAccountData from 'src/hooks/useAccountData';
import { MarginInfo as MarginInfoData } from 'src/stores/driftStoreTypes';
import UI_UTILS from '../../utils/uiUtils';
import React from 'react';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useUserAccountIsReady from 'src/hooks/useUserAccountIsReady';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

export type MarginDisplayInfo = {
	netUsdValue: string;
	totalCollateral: string;
	accountBalance: string;
	unrealisedPnl: string;
	unrealisedFunding: string;
	freeCollateral: string;
	accountLeverage: string;
	marginRatio: string;
	unsettledPnl: string;
	unsettledPnlNum: number;
	maintenanceReq: string;
	maintenanceReqPct: string;
	initialReq: string;
	initialReqPct: string;
	totalQuoteInOrders: string;
	quoteInOrders: string;
	quoteInLpOrders: string;
	health: number;
};

const getMarginAccountDisplayInfo = (
	marginInfo: MarginInfoData,
	health: number
): MarginDisplayInfo => {
	if (!marginInfo) return;

	const marginRatio =
		marginInfo.marginRatioPct === 0
			? '100'
			: marginInfo.marginRatioPct > 1000
			? '>1000'
			: marginInfo.marginRatioPct.toFixed(2);

	const unsettledPnlNum = BigNum.from(
		marginInfo.totalUnsettledPnl,
		QUOTE_PRECISION_EXP
	).toNum();

	return {
		health,
		netUsdValue: `$${BigNum.from(
			marginInfo.netUsdValue,
			QUOTE_PRECISION_EXP
		).toMillified()}`,
		totalCollateral: UI_UTILS.toNotional(
			BigNum.from(
				marginInfo.totalMaintenanceCollateral,
				QUOTE_PRECISION_EXP
			).toNum()
		),
		accountBalance: UI_UTILS.toNotional(
			BigNum.from(marginInfo.collateral, QUOTE_PRECISION_EXP).toNum()
		),
		unrealisedPnl: UI_UTILS.toNotional(
			BigNum.from(marginInfo.unrealisedPnL, QUOTE_PRECISION_EXP).toNum()
		),
		unrealisedFunding: UI_UTILS.toNotional(
			BigNum.from(marginInfo.unrealisedFundingPnL, PRICE_PRECISION_EXP).toNum()
		),
		freeCollateral: UI_UTILS.toNotional(
			BigNum.from(marginInfo.freeCollateral, QUOTE_PRECISION_EXP).toNum()
		),
		accountLeverage: `${marginInfo.leverage.toFixed(2)}x`,
		marginRatio: `${marginRatio}%`,
		unsettledPnl: UI_UTILS.toNotional(unsettledPnlNum),
		unsettledPnlNum,
		maintenanceReq: UI_UTILS.toNotional(
			BigNum.from(marginInfo.maintenanceReq, QUOTE_PRECISION_EXP).toNum()
		),
		maintenanceReqPct: `${(
			(BigNum.from(marginInfo.maintenanceReq, QUOTE_PRECISION_EXP).toNum() /
				BigNum.from(
					marginInfo.totalMaintenanceCollateral,
					QUOTE_PRECISION_EXP
				).toNum()) *
			100
		).toFixed(2)}%`,
		initialReq: UI_UTILS.toNotional(
			BigNum.from(marginInfo.initialReq, QUOTE_PRECISION_EXP).toNum()
		),
		initialReqPct: `${(
			(BigNum.from(marginInfo.initialReq, QUOTE_PRECISION_EXP).toNum() /
				BigNum.from(marginInfo.totalCollateral, QUOTE_PRECISION_EXP).toNum()) *
			100
		).toFixed(2)}%`,
		quoteInOrders: UI_UTILS.toNotional(
			BigNum.from(marginInfo.quoteInOpenOrders, QUOTE_PRECISION_EXP).toNum()
		),
		quoteInLpOrders: UI_UTILS.toNotional(
			BigNum.from(marginInfo.quoteInLpOrders, QUOTE_PRECISION_EXP).toNum()
		),
		totalQuoteInOrders: UI_UTILS.toNotional(
			BigNum.from(
				marginInfo.quoteInLpOrders.add(marginInfo.quoteInOpenOrders),
				QUOTE_PRECISION_EXP
			).toNum()
		),
	};
};

const verticalBreakpoint = 1000;

export const MarginInfo = (props: { expanded?: boolean }) => {
	const marginInfo = useAccountData()?.marginInfo;
	const connected = useWalletIsConnected();

	const userAccountIsReady = useUserAccountIsReady();
	const userClient = useDriftAccountStore((s) => {
		return s.accounts[s.currentUserKey]?.client;
	});
	const health =
		connected && userAccountIsReady && userClient
			? userClient.getHealth()
			: 100;

	const marginInfoDisplay = getMarginAccountDisplayInfo(marginInfo, health);

	// Attempted performance improvement : Only render new values when the actual string representations of the account margin info has changed
	const marginInfoToRender = useMemo(() => {
		return marginInfoDisplay;
	}, [JSON.stringify(marginInfoDisplay)]);

	return !props.expanded ? (
		<div className="z-30 flex w-full min-h-full border rounded lg:absolute bg-container-bg border-container-border">
			<AccountHealth marginInfoDisplay={marginInfoToRender} />
		</div>
	) : (
		<div className="relative h-full p-2 overflow-auto bg-container-bg thin-scroll">
			<div className="h-full max-w-[1400px]">
				<MobileAndTabletAccountHealth marginInfoDisplay={marginInfoToRender} />
			</div>
		</div>
	);
};

MarginInfo.verticalBreakpoint = verticalBreakpoint;
export default React.memo(MarginInfo);
