'use client';

import Button from 'src/components/Button';
import TextField from 'src/components/Inputs/TextField';
import { useEffect, useRef, useState } from 'react';
import PopoverWrapper, { FloatingPopoverProps } from '../PopoverWrapper';

const PaginationInputPopup = (
	props: FloatingPopoverProps & {
		initialValue: number;
		maxValue: number;
		onClose: (newValue: number) => void;
	}
) => {
	const [currentVal, setCurrentVal] = useState(``);

	const handleExitClose = () => {
		props.onClose(props.initialValue);
	};

	const handleSaveClose = () => {
		let valueToTry = parseInt(currentVal ? currentVal : '0');

		valueToTry = Math.min(valueToTry, props.maxValue);
		valueToTry = Math.max(valueToTry, 0);

		props.onClose(valueToTry);
	};

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, [inputRef.current]);

	return (
		<PopoverWrapper
			ref={props.setFloating}
			style={props.floatingStyles}
			{...props.getFloatingProps()}
		>
			<div className="flex flex-col p-4 space-y-2 font-thin">
				<div className="text-xs whitespace-nowrap">Jump to page</div>
				<div className="inline-flex items-center space-x-2">
					<div className="w-[40px]">
						<TextField.Default
							type="number"
							value={currentVal}
							onChange={setCurrentVal}
							onEnter={handleSaveClose}
							onEscape={handleExitClose}
							ref={inputRef}
						/>
					</div>
					<Button.Secondary onClick={handleSaveClose} size="MEDIUM">
						<div>Go</div>
					</Button.Secondary>
				</div>
			</div>
		</PopoverWrapper>
	);
};

export default PaginationInputPopup;
