'use client';

import { Info, Open } from '@drift-labs/icons';
import { BigNum, QUOTE_PRECISION_EXP, ZERO } from '@drift-labs/sdk';
import React, { useState } from 'react';
import useShowAccountValues from '../../hooks/useShowAccountValues';
import Button from '../Button';
import TableV2 from '../Tables/TableV2';
import { twMerge } from 'tailwind-merge';
import useSafePush from 'src/hooks/useSafePush';
import useDriftActions from 'src/hooks/useDriftActions';
import Text from '../Text/Text';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import Tooltip from '../Tooltip/Tooltip';
import { OrderedPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';
import Select from '../Inputs/Select';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';

const UnsettledRow = (props: {
	highlighted?: boolean;
	tableGrid?: string;
	expanded?: boolean;
	className?: string;
}) => {
	const isMobile = useIsMobileScreenSize();
	const [submitting, setSubmitting] = useState(false);
	const safePush = useSafePush();
	const actions = useDriftActions();
	const showAccountValues = useShowAccountValues();
	const marginInfo = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.marginInfo
	);

	const totalUnsettledPnl = marginInfo?.totalUnsettledPnl;
	const marketsNeedingSettlement = marginInfo?.marketsNeedingSettlement;

	const settleNeeded = marketsNeedingSettlement?.length > 0;
	let cantSettleTooltipText;

	if (settleNeeded) {
		cantSettleTooltipText = `${
			marketsNeedingSettlement?.length > 1
				? marketsNeedingSettlement
						.map((marketIndex) => OrderedPerpMarkets[marketIndex].symbol)
						.join(', ')
						.trimEnd()
				: OrderedPerpMarkets[marketsNeedingSettlement[0]].symbol
		} market(s) can't be settled now because there's not enough liquidity in the P&L pool. Please try to settle the negative P&L balances in the pool first.`;
	}

	const unsettledBalance = BigNum.from(
		totalUnsettledPnl || ZERO,
		QUOTE_PRECISION_EXP
	);

	const handleClickedUnsettled = () => {
		safePush('/overview/positions');
	};

	const handleSettle = async () => {
		setSubmitting(true);
		await actions.settleAllPnls();
		setSubmitting(false);
	};

	return isMobile ? (
		<div className={props.className}>
			<div className={twMerge('flex flex-row items-center justify-between')}>
				<div>
					<Text.BODY3 className="text-text-label">Unsettled</Text.BODY3>
					<div className="">
						<Text.BODY1 className="text-text-emphasis">
							{showAccountValues ? unsettledBalance.toNotional() : '*****'}
						</Text.BODY1>
						<Open size={14} className="relative top-[3px] ml-2" />
						{settleNeeded && (
							<div className="block">
								<Tooltip content={cantSettleTooltipText}>
									<div className="inline-flex items-center px-1 py-0.5 bg-opacity-25 rounded-md bg-yellow-50">
										<Info size={12} color={'var(--yellow-50)'} />
										<span className="inline-flex mx-0.5 text-yellow-50">
											Can&apos;t settle
										</span>
									</div>
								</Tooltip>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-row items-center">
					<div className="flex flex-row items-center">
						<Button.Secondary
							size="SMALL"
							onClick={handleSettle}
							highlight={props.highlighted}
							disabled={submitting || unsettledBalance.eqZero()}
						>
							Settle
						</Button.Secondary>
						{settleNeeded && (
							<Select.Settle
								options={marketsNeedingSettlement.map(
									(mktIndex) => OrderedPerpMarkets[mktIndex]
								)}
								className="ml-2"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	) : (
		<>
			<TableV2.BodyRow
				lastColumnJustify="start"
				grid={props.tableGrid}
				className={twMerge(['py-2', props.highlighted ? 'py-3' : undefined])}
				strongBottomBorder={props.highlighted}
			>
				<TableV2.BodyCell className="items-center">
					Unsettled{' '}
					<Tooltip
						className="text-text-label ml-1.5"
						placement="right"
						allowHover
						content={
							<div>
								This includes funding fees, position P&L, and referral fees.
								Claiming unsettled balances will update your available USDC
								balance for staking and withdrawals.
								<a
									href="https://docs.drift.trade/profit-loss/what-is-unsettled-profit-loss"
									target="_blank"
									rel="noreferrer"
									className="ml-1"
								>
									Learn More <Open size={14} className="relative top-[3px]" />
								</a>
							</div>
						}
					/>
				</TableV2.BodyCell>
				<TableV2.BodyCell className="items-center">
					<div onClick={handleClickedUnsettled} className="cursor-pointer">
						<Text.BODY2 className="text-text-emphasis">
							{showAccountValues ? unsettledBalance.toNotional() : '*****'}{' '}
						</Text.BODY2>
						<Open
							color="var(--text-label)"
							className="ml-1 relative top-[3px]"
						/>
						{settleNeeded && (
							<div className="block">
								<Tooltip content={cantSettleTooltipText}>
									<div className="inline-flex items-center px-1 py-0.5 bg-opacity-25 rounded-md bg-yellow-50">
										<Info size={12} color={'var(--yellow-50)'} />
										<span className="inline-flex mx-0.5 text-yellow-50">
											Can&apos;t settle
										</span>
									</div>
								</Tooltip>
							</div>
						)}
					</div>
				</TableV2.BodyCell>

				<TableV2.BodyCell className="items-center">-</TableV2.BodyCell>

				<TableV2.BodyCell className="items-center">-</TableV2.BodyCell>

				{props.expanded && (
					<TableV2.BodyCell className="items-center">-</TableV2.BodyCell>
				)}

				<TableV2.BodyCell className="inline-flex items-center p-0 m-0 overflow-visible">
					<Button.Secondary
						size="SMALL"
						onClick={handleSettle}
						highlight={props.highlighted}
						disabled={submitting || unsettledBalance.eqZero()}
					>
						Settle
					</Button.Secondary>
					{settleNeeded && (
						<Select.Settle
							options={marketsNeedingSettlement.map(
								(mktIndex) => OrderedPerpMarkets[mktIndex]
							)}
							className="ml-2"
						/>
					)}
				</TableV2.BodyCell>
			</TableV2.BodyRow>
		</>
	);
};

export default UnsettledRow;
